<template>
    <page-view class="pro-server" :up-class="'pro-server'">
        <div class="pro-content">
            <h2>产品与服务</h2>
            <div class="pro-item">
                <div class="item-title">基础功能介绍:</div>
                <div class="item-txt">
                    百川智慧商展云平台交易金额、交易过程,可全程实时追踪，环境规范透明。
                </div>
            </div>
            <div class="pro-item">
                <div class="item-title">核心功能介绍:</div>
                <ul class="list">
                    <li class="list-item">
                        <div class="list-item-name">商展产业链智造企业</div>
                        <div class="list-item-detail">获取长期稳定订单，有效控制成本
                            保证品质、提高生产效率、减少不必
                            要的消耗和降低淡旺季影响。</div>
                    </li>
                    <li class="list-item">
                        <div class="list-item-name">原材料供应企业</div>
                        <div class="list-item-detail">从零散的材料批发、零售向规模化、
                            集约化供应转型，使得新材料的推厂
                            更加高效。</div>
                    </li>
                    <li class="list-item">
                        <div class="list-item-name">品牌零售企业</div>
                        <div class="list-item-detail">商展供应链的完善服务，有助于品牌大
                            幅缩减非盈利部门开支，减少开店成本
                            ，提升了店铺商展品质和后期维护保障。</div>
                    </li>
                    <li class="list-item">
                        <div class="list-item-name">商展服务企业</div>
                        <div class="list-item-detail">解决最难处理的服务订单不稳定、经营
                            较难上规模问题，因共享关系的建立而
                            获得更多品牌服务订单和更专业化服务。</div>
                    </li>
                </ul>
            </div>
        </div>
        <footer-com></footer-com>
    </page-view>
</template>
<script>
export default {
    name: "ProServer"
}
</script>
<style lang='scss' scoped>
.pro-server {
    .pro-content {
        width: 70%;
        margin: 0% auto 5%;
        padding: 20px;
        box-sizing: border-box;

        >h2 {
            font-weight: normal;

        }

        .pro-item {
            margin-top: 50px;

            .item-title {
                border-left: 3px solid $primaryColor;
                padding-left: 10px;
                font-size: 18px;
                line-height: 1;
                margin-bottom: 20px;
            }

            .item-txt {
                color: #636363;
                box-sizing: border-box;
                padding: 0 20px;
            }

            .list {
                padding: 20px;
                box-sizing: border-box;
                gap: 10px;

                @include flexbox(row) {
                    flex-wrap: wrap;
                }
                .list-item {
                    padding: 20px;
                    height: 200px;
                    box-sizing: border-box;
                    width: max(200px,49%);
                    margin: auto;
                    color: #fff;

                    &:nth-child(1) {
                        background-color: #9CEC80;
                        box-shadow: 0px 0px 20px 0px rgba(155, 245, 123, 1);
                    }

                    &:nth-child(2) {
                        background-color: #98CAFD;
                        box-shadow: 0px 0px 20px 0px rgba(152, 202, 253, 1);
                    }

                    &:nth-child(3) {
                        background-color: #FB9191;
                        box-shadow: 0px 0px 20px 0px rgba(251, 145, 145, 1);
                    }

                    &:nth-child(4) {
                        background-color: #DAB18F;
                        box-shadow: 0px 0px 20px 0px rgba(218, 177, 143, 1);
                    }

                    .list-item-detail {
                        margin-top: 20px;
                        font-size: 14px;
                        line-height: 1.6;
                        // text-indent: 2em;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .pro-server{
        .pro-content{
            width: 100%;
        }
    }
}
</style>